import React, { useState, useEffect } from "react";
import listTimes from "../../utils/listTimes";

import "./styles.css";

function TimeInput({ title, value, ...rest }) {
  const [time, setTime] = useState("");
  const [times, setTimes] = useState([]);

  useEffect(() => {
    setTime(value);
    listTimes().then((res) => setTimes(res));
  }, [value]);

  return (
    <div className="time-input">
      <label className="label">{title}</label>
      <select className="input" value={time} {...rest}>
        {times.map((item) => (
          <option value={item} key={item}>
            {item}
          </option>
        ))}
      </select>
    </div>
  );
}

export default TimeInput;
