import React from "react";

import "./styles.css";

function Loading({ show }) {
  return (
    <div className={show ? "loading-box" : "loading-box hidden"}>
      <div className="loading-circle" />
    </div>
  );
}

export default Loading;
