import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../components/Header";
import Modal from "../../components/Modal";
import Loading from "../../components/Loading";
import {
  FiChevronRight,
  FiChevronLeft,
  FiChevronsLeft,
  FiChevronsRight,
} from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { AiOutlineReload } from "react-icons/ai";
import {
  BiCalendarCheck,
  BiCalendarEvent,
  BiCalendarX,
  BiCalendarExclamation,
} from "react-icons/bi";
import ModalNotShowed from "../../components/ModalNotShowed";
import ModalReport from "../../components/ModalReport";
import ModalCanceled from "../../components/ModalCanceled";
import { useMediaQuery } from "react-responsive";

import listTimes from "../../utils/listTimes";
import listDays from "../../utils/listDays";
import isToday from "../../utils/isToday";
import buildScheduleMatriz from "../../utils/buildScheduleMatriz";
import beforeToday from "../../utils/beforeToday";

import api from "../../services/api";

import "./styles.css";

function Home() {
  const history = useHistory();

  const [times, setTimes] = useState([]);
  const [dayShowing, setDayShowing] = useState(0);
  const [daysWeekShowing, setDaysWeekShowing] = useState(
    listDays(dayShowing / 7)
  );

  const [showModal, setShowModal] = useState(false);
  const [timeInModal, setTimeInModal] = useState("08:00");
  const [dayOfWeekInModal, setDayOfWeekInModal] = useState(0);

  const [modalReport, setModalReport] = useState(false);
  const [modalNotShowedUp, setModalNotShowedUp] = useState(false);
  const [modalCanceled, setModalCanceled] = useState(false);

  const [idSchedule, setIdSchedule] = useState(-1);

  const [titleModal, setTitleModal] = useState("Adicionar agendamento");

  const [matriz, setMatriz] = useState([]);

  const [doctors, setDoctors] = useState([]);

  const [token, setToken] = useState("");

  const [loading, setLoading] = useState(false);

  const [update, setUpdate] = useState(0);

  const [datesRule, setDatesRule] = useState([]);

  const [toastMessage, setToastMessage] = useState({
    message: "",
    show: false,
  });

  const [toastUpdate, setToastUpdate] = useState({
    message: "",
    show: false,
  });

  const [modalEditDoctor, setModalEditDoctor] = useState({
    doctor: {
      id: 0,
      name: "",
      date: "",
    },
    show: false,
  });

  const listDaysWeek = ["SEG", "TER", "QUA", "QUI", "SEX", "SÁB", "DOM"];

  const isMobile = useMediaQuery({ query: "(max-width: 550px)" });

  //Recomanda o recarregamento da página a cada minuto a partir de 2 minutos
  useEffect(() => {
    //Tempo para recomendar atualização em minutos
    const minutesUpdate = 1;
    const timeUpdate = minutesUpdate * 60000;

    // eslint-disable-next-line
    setTimeout(() => setUpdate(update + minutesUpdate), timeUpdate);
    setTimeout(() => setToastUpdate({ show: false }), timeUpdate - 750);

    if (update === 0) {
      //clear all timeouts
      var id = window.setTimeout(function () {}, 0);

      while (id--) {
        window.clearTimeout(id);
      }

      // eslint-disable-next-line
      setTimeout(() => setUpdate(minutesUpdate + minutesUpdate), timeUpdate);
      setTimeout(() => setToastUpdate({ show: false }), timeUpdate - 750);
    }

    setToastUpdate({
      message: `${update} minuto${
        update > 1 ? "s" : ""
      } sem atualizar, recarregue para atualizar`,
      show: true,
    });

    if (update < 2) {
      setToastUpdate({ show: false });
    }

    const token = localStorage.getItem("token");

    if (!token || token === "") {
      history.push("/login");
      return;
    }

    // eslint-disable-next-line
  }, [update]);

  //Recupera o token e a semana em destaque
  useEffect(() => {
    //pega o token
    const token = localStorage.getItem("token");

    if (token && token !== "") {
      setToken(token);

      api
        .get("scheduler", {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          res.data.scheduler.name === "Administrador"
            ? history.push("/admin")
            : res.data.scheduler.name.includes("admin")
            ? history.push("/home-admin")
            : history.push("/home");
        })
        .catch((err) => {
          history.push("/login");
        });
    } else {
      history.push("/login");
    }

    //Abir no dia atual
    isMobile &&
      listDays(0).map((item, index) => {
        if (isToday(item)) {
          setDayShowing(index);
        }
        return item;
      });
    // eslint-disable-next-line
  }, [history]);

  useEffect(() => {
    listTimes().then((res) => setTimes(res));
  }, []);

  //quando o modal deixar de ser visivel, atualizar a semana mostrada
  useEffect(() => {
    if (!showModal) {
      //atualiza os dias da semana sendo mostrado
      setDaysWeekShowing(listDays(dayShowing / 7));
      //grava no storage a semana que esta sendo mostrada
      localStorage.setItem("dayShowing", dayShowing / 7);

      //reseta o atualizador
      setUpdate(0);
    } else {
      return;
    }

    loadSchedulings();
    loadDoctors();
    dateTimeRules();

    // eslint-disable-next-line
  }, [dayShowing, showModal, token]);

  //Verifica se o toast foi modificado
  // eslint-disable-next-line
  function showToast(message) {
    setToastMessage({ message, show: true });
    setTimeout(() => setToastMessage({ message, show: false }), 4000);
  }

  //Carrega os agendamentos da semana
  async function loadSchedulings() {
    setLoading(true);

    if (token === "") {
      return;
    }

    //Pega a lista de agendamentos da semana
    api
      .get("scheduling", {
        headers: {
          authorization: token,
        },
        params: {
          initialDate: listDays(dayShowing / 7)[0],
          finalDate: listDays(dayShowing / 7)[6],
        },
      })
      .then((res) => {
        const schedulings = res.data.map((item) => {
          return { ...item, date: new Date(item.date) };
        });

        buildScheduleMatriz(schedulings, dayShowing / 7)
          .then((res) => setMatriz(res))
          .finally(() => setLoading(false));
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function loadDoctors() {
    if (token === "") {
      return;
    }

    setLoading(true);

    api
      .get("doctor", {
        headers: {
          authorization: token,
        },
        params: {
          date1: listDays(dayShowing / 7)[0],
          date2: listDays(dayShowing / 7)[1],
          date3: listDays(dayShowing / 7)[2],
          date4: listDays(dayShowing / 7)[3],
          date5: listDays(dayShowing / 7)[4],
          date6: listDays(dayShowing / 7)[5],
          date7: listDays(dayShowing / 7)[6],
        },
      })
      .then((res) => {
        setDoctors(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function saveDoctors() {
    const doctor = modalEditDoctor.doctor;

    if (!doctor) {
      return;
    }

    setLoading(true);

    api
      .post(
        "doctor",
        {
          name: doctor.name.trim() === "" ? null : doctor.name,
          date: doctor.date.split("T")[0],
        },
        { headers: { authorization: token } }
      )
      .then((res) => {
        setLoading(false);
        loadDoctors();
      })
      .catch((err) => {
        alert("Erro inesperado");
        setLoading(false);
      });

    setModalEditDoctor({ show: false, doctor: { id: 0, name: "", date: "" } });
  }

  // eslint-disable-next-line
  function addScheduling(time, dayOfWeek) {
    //passa ao modal o horario selecionado
    setTimeInModal(time);
    //passa ao modal o dia da semana selecionado
    setDayOfWeekInModal(dayOfWeek);
    //muda o titulo do modal
    setTitleModal("Adicionar agendamento");
    //mostra o modal
    setShowModal(true);
  }

  function editScheduling(id) {
    //passa o id do agendamento selecionado
    setIdSchedule(id);
    //muda o titulo do modal
    setTitleModal("Editar agendamento");
    //mostra o modal
    setShowModal(true);
  }

  //Carrega os horario que não pode agendar
  function dateTimeRules() {
    const token = localStorage.getItem("token");

    if (!token) {
      history.push("/login");
      return;
    }

    api
      .get("rules", {
        headers: {
          authorization: token,
        },
      })
      .then((res) => {
        setDatesRule(res.data);
      })
      .catch((err) => {
        console.log({ err });
      });
  }

  function verifyBlockDay(index, indexScheduling) {
    const day = isMobile ? dayShowing % 7 : index;

    return datesRule.find(
      (item) => item.weekDate === day && item.time === times[indexScheduling]
    );
  }

  function setDayShowingVariant(variant) {
    if (variant === 0) {
      //Abir no dia atual
      isMobile
        ? listDays(0).map((item, index) => {
            if (isToday(item)) {
              setDayShowing(index);
            }
            return item;
          })
        : setDayShowing(0);
      return;
    }

    isMobile
      ? setDayShowing(dayShowing + variant)
      : setDayShowing(dayShowing + variant * 7);
  }

  return (
    <div className="home-page">
      <Loading show={loading} />

      {showModal && (
        <Modal
          title={titleModal}
          visible={showModal}
          setVisiblility={setShowModal}
          initialTime={timeInModal}
          initialDate={daysWeekShowing[dayOfWeekInModal]}
          scheduleId={idSchedule}
          setScheduleId={setIdSchedule}
        />
      )}

      {toastMessage.show ||
        (toastUpdate.show && (
          <div className="toast">
            {/* Toast de mensagens no canto inferior esquerdo */}
            <div className={toastMessage.show ? "toast-1" : "toast-1 hidden"}>
              <p>{toastMessage.message}</p>
            </div>
            {/* Toast de atualização no centro */}
            <div className={toastUpdate.show ? "toast-2" : "toast-2 hidden"}>
              <p>{toastUpdate.message}</p>
              <AiOutlineReload
                onClick={() => {
                  loadSchedulings();
                  loadDoctors();
                  dateTimeRules();
                  setUpdate(0);
                }}
                className="button-toast"
              />
              <MdClose onClick={() => setUpdate(0)} className="button-toast" />
            </div>
          </div>
        ))}

      {/* Modal de edição do nome do médico */}
      {modalEditDoctor.show && (
        <div className="modal-edit-doctor">
          <div className="modal-active">
            <input
              value={modalEditDoctor.doctor.name}
              onChange={(event) =>
                setModalEditDoctor({
                  ...modalEditDoctor,
                  doctor: {
                    ...modalEditDoctor.doctor,
                    name: event.target.value,
                  },
                })
              }
            />
            <div className="modal-box-button">
              <button
                onClick={() =>
                  setModalEditDoctor({
                    show: false,
                    doctor: { id: 0, name: "", date: "" },
                  })
                }
              >
                Cancelar
              </button>
              <button onClick={() => saveDoctors()}>Salvar</button>
            </div>
          </div>
        </div>
      )}

      {/* Modal para ver os agendamentos marcados como "Não compareceu" */}
      {modalReport && (
        <ModalReport
          onEditScheduling={editScheduling}
          show={modalReport}
          setShow={setModalReport}
        />
      )}

      {/* Modal para ver os agendamentos marcados como "Não compareceu" */}
      {modalNotShowedUp && (
        <ModalNotShowed
          onEditScheduling={editScheduling}
          show={modalNotShowedUp}
          setShow={setModalNotShowedUp}
        />
      )}

      {/* Modal para ver os agendamentos marcados como "Não compareceu" */}
      {modalCanceled && (
        <ModalCanceled
          onEditScheduling={editScheduling}
          show={modalCanceled}
          setShow={setModalCanceled}
        />
      )}

      <Header>
        <div className="icons-header">
          <BiCalendarCheck
            className="icon"
            title="Relatório dos agendamentos"
            onClick={() => {
              setModalReport(true);
            }}
          />
          <BiCalendarExclamation
            className="icon"
            title='Marcados como "não comparecido"'
            onClick={() => {
              setModalNotShowedUp(true);
            }}
          />
          <BiCalendarX
            className="icon"
            title="Agendamentos cancelados"
            onClick={() => {
              setModalCanceled(true);
            }}
          />
        </div>
      </Header>

      <main>
        <div className="header-calendar">
          <div className="item-actions">
            <div>
              <button
                onClick={() => setDayShowingVariant(-1)}
                title="Ir para semana anterior"
              >
                <FiChevronLeft />
              </button>
              {isMobile && (
                <button
                  onClick={() => setDayShowingVariant(-7)}
                  title="Ir para semana anterior"
                >
                  <FiChevronsLeft />
                </button>
              )}
            </div>
            <button
              onClick={() => setDayShowingVariant(0)}
              title="Ir para semana atual"
            >
              <BiCalendarEvent />
            </button>
            <div>
              <button
                onClick={() => setDayShowingVariant(1)}
                title="Ir para próxima semana"
              >
                <FiChevronRight />
              </button>
              {isMobile && (
                <button
                  onClick={() => setDayShowingVariant(7)}
                  title="Ir para próxima semana"
                >
                  <FiChevronsRight />
                </button>
              )}
            </div>
          </div>
          {matriz.map((days, index) => {
            if (isMobile && index > 0) {
              return null;
            }
            return (
              <div
                key={daysWeekShowing[index]}
                className={
                  isToday(daysWeekShowing[index])
                    ? "item-day today"
                    : "item-day"
                }
              >
                <strong>
                  {daysWeekShowing[index].split("-")[2]}/
                  {daysWeekShowing[index].split("-")[1]}/
                  {daysWeekShowing[index].split("-")[0].slice(2, 4)}
                </strong>
                <strong>
                  {listDaysWeek[isMobile ? dayShowing % 7 : index]}
                </strong>
                <label>
                  {`( ${[0, ...days].reduce((result, scheduling) =>
                    scheduling ? result + 1 : result
                  )} )`}
                </label>
                <label
                  className={`${
                    doctors && doctors[index]
                      ? "name-doctor-day"
                      : "name-doctor-day no-doctor"
                  }`}
                  onClick={() =>
                    setModalEditDoctor({
                      doctor: doctors[index] || {
                        name: "",
                        id: 0,
                        date: daysWeekShowing[index],
                      },
                      show: true,
                    })
                  }
                >
                  {doctors && doctors[index]
                    ? doctors[index].name
                    : "Sem médico"}
                </label>
              </div>
            );
          })}
          <div className="scroll-align" />
        </div>
        <div className="calendar">
          <div className="times-calendar">
            {times.map((item) => {
              return <div key={item}>{item}</div>;
            })}
          </div>

          {matriz.map((days, index) => {
            if (isMobile && index > 0) {
              return null;
            }
            return (
              <div className="days-calendar" key={index}>
                {days.map((scheduling, indexScheduling) =>
                  scheduling ? (
                    <button
                      key={indexScheduling}
                      className={
                        isToday(daysWeekShowing[dayShowing % 7])
                          ? `button-calendar today ${scheduling.status}`
                          : `button-calendar ${scheduling.status}`
                      }
                      onClick={() => editScheduling(scheduling.id)}
                      title={scheduling.pacient}
                    >
                      <input
                        className="pacient-name"
                        value={scheduling.pacient}
                        disabled
                      />
                    </button>
                  ) : (
                    <button
                      key={indexScheduling}
                      className={
                        verifyBlockDay(index, indexScheduling)
                          ? "button-calendar block"
                          : "button-calendar"
                      }
                      onClick={() =>
                        !verifyBlockDay(index, indexScheduling) &&
                        (beforeToday(
                          daysWeekShowing[index],
                          times[indexScheduling]
                        )
                          ? showToast(
                              "Não pode agendar para horários anteriores a agora"
                            )
                          : addScheduling(times[indexScheduling], index))
                      }
                    ></button>
                  )
                )}
              </div>
            );
          })}
        </div>
      </main>
    </div>
  );
}

export default Home;
