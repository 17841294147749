import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../components/Header";
import api from "../../services/api";

import "./styles.css";

function Register() {
  const history = useHistory();

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordAdmin, setPasswordAdmin] = useState("");

  const [token, setToken] = useState("");

  //Recupera o token
  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token && token !== "") {
      api
        .get("scheduler", {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          setToken(token);

          res.data.name === "Administrador"
            ? history.push("/admin/register")
            : history.push("");
        })
        .catch((err) => {
          history.push("/login");
        });
    } else {
      history.push("/login");
    }
  }, [history]);

  function register(event) {
    event.preventDefault();

    if(name === "") {
      alert("Nome não pode ser vazio");
      return;
    }

    if(password.includes(" ") || passwordAdmin.includes(" ")) {
      alert("Senha não pode conter espaços")
      return;
    }

    if(password.length < 6 || passwordAdmin.length < 6) {
      alert("Senha deve ter pelo menos 6 caracteres");
      return;
    }
 
    api
      .post(
        "scheduler",
        {
          name,
          password,
          passwordAdmin,
        },
        {
          headers: {
            authorization: token,
          },
        }
      )
      .then((res) => {
        alert("Loja cadastrada com sucesso");
      })
      .catch((err) => {
        const error = err.response ? err.response.data.error : err.message;

        alert(error || "Erro inesperado");
      });

    setName("");
    setPassword("");
    setPasswordAdmin("");
  }

  return (
    <div className="register-page">
      <Header />

      <form onSubmit={register}>
        <h2>Cadastro de loja</h2>

        <label htmlFor="name">Nome</label>
        <input
          name="name"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />

        <label htmlFor="password">Senha</label>
        <input
          name="password"
          type="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />

        <label htmlFor="password">Senha do administrador</label>
        <input
          name="password"
          type="password"
          value={passwordAdmin}
          onChange={(event) => setPasswordAdmin(event.target.value)}
        />

        <button className="submit" type="submit">
          Cadastrar
        </button>
        <button className="cancel" onClick={() => history.push("/admin")}>
          Cancelar
        </button>
      </form>
    </div>
  );
}

export default Register;
