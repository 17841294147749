import React, { useState, useEffect } from "react";

import "./styles.css";

function TextInput({ title, value, ...rest }) {
  const [date, setDate] = useState("");

  useEffect(() => {
    setDate(value);
  }, [value]);

  return (
    <div className="date-input">
      <label className="label">{title}</label>
      <input className="input" type="date" value={date} {...rest} />
    </div>
  );
}

export default TextInput;
