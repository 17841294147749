import React, { useEffect, useState } from "react";
import Switch from "react-switch";

import "./styles.css";

export default function SwitchToggle() {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const theme = localStorage.getItem("theme");

    if (theme && theme === "dark") {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }
  }, []);

  useEffect(() => {
    document.documentElement.className = darkMode
      ? "theme-dark"
      : "theme-light";
    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute("content", darkMode ? "#3a4756" : "#c4dcf8");
  }, [darkMode]);

  function setThemeMode() {
    localStorage.setItem("theme", !darkMode ? "dark" : "light");
    setDarkMode(!darkMode);
  }

  return (
    <Switch
      className="toggle-theme"
      onChange={() => setThemeMode()}
      onColor="#444"
      onHandleColor="#222"
      offColor="#ccc"
      offHandleColor="#fff"
      checked={darkMode}
      handleDiameter={30}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      height={25}
      width={55}
    />
  );
}
