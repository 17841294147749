import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../components/Header";
import api from "../../services/api";

import "./styles.css";

function Admin() {
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token && token !== "") {
      api
        .get("scheduler", {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          res.data.name === "Administrador"
            ? history.push("/admin")
            : history.push("/home");
        })
        .catch((err) => {
          history.push("/login");
        });
    } else {
      history.push("/login");
    }
  }, [history]);

  return (
    <div className="admin-page">
      <Header />

      <main>
        <button onClick={() => history.push("/admin/register")}>
          Cadastrar loja
        </button>

        <button onClick={() => history.push("/admin/alter")}>
          Alterar senha da loja
        </button>

        <button onClick={() => history.push("/admin/shops")}>
          Lista de lojas
        </button>
      </main>
    </div>
  );
}

export default Admin;
