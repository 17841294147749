import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Home from "./pages/Home";
import Admin from "./pages/Admin";
import Login from "./pages/Login";
import AlterPassword from "./pages/AlterPassword";
import Register from "./pages/Register";
import ListSchedulers from "./pages/ListSchedulers";
import Presentation from "./pages/Presentation";
import HomeAdmin from "./pages/HomeAdmin";

function Routes() {
  return (
    <BrowserRouter>
      <Route component={Presentation} path="/" exact />
      <Route component={Login} path="/login" />

      {/* Loja */}
      <Route component={Home} path="/home" />

      {/* Administrador da loja */}
      <Route component={HomeAdmin} path="/home-admin" />

      {/* Administrador do sistema */}
      <Route component={Admin} path="/admin" exact />
      <Route component={Register} path="/admin/register" />
      <Route component={AlterPassword} path="/admin/alter" />
      <Route component={ListSchedulers} path="/admin/shops" />
    </BrowserRouter>
  );
}

export default Routes;
