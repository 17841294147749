import React from 'react';

import "./styles.css"

const PrintComponent = React.forwardRef((props, ref) => {

  const initialDateFormated = Intl.DateTimeFormat("pt-br").format(new Date(props.period.initial === "" ? 0 : props.period.initial))
  const finalDateFormated = Intl.DateTimeFormat("pt-br").format(new Date(props.period.final === "" ? 0 : props.period.final))

  return (
    <table ref={ref} className="document">
      <tbody>
        {props.schedulings.map((scheduling, index) => {
          if(index % 10 === 0) {
            return (
              <tr className="print-page">
                <div className="header-page">
                  <strong>{props.title}</strong>
                  <p>Filial: {scheduling.schedulerName}</p>
                  <p>{`${props.period.initial === "" ? "Período: Todos" : "Período: " + initialDateFormated + " a " + finalDateFormated }`}</p>
                </div>
                <div className="content-page">
                  {[0,1].map(i => (
                    <>
                      {props.schedulings.slice(index + (5 * i), index + 5 + (5 * i)).map(item => (
                        <td
                          key={item.id}
                          className="item-scheduling"
                        >
                          <div>
                            <strong>Paciente</strong>
                            <p>{item.pacient.length >= 30 ? abbreviateSurname(item.pacient) : item.pacient}</p>
                          </div>
                          <div>
                            <strong>Colaborador</strong>
                            <p>{item.collaborator}</p>
                          </div>
                          <div>
                            <strong>Horário</strong>
                            <p>
                              {Intl.DateTimeFormat("pt-br", {
                                hour: "numeric",
                                minute: "numeric",
                                day: "numeric",
                                month: "numeric",
                                year: "numeric",
                              }).format(new Date(item.date))}
                            </p>
                          </div>
                          <div>
                            <strong>Telefone</strong>
                            <p>{item.phone}</p>
                          </div>
                        </td>
                      ))}
                    </>
                  ))}
                </div>
              
              </tr>
            )
          }
          return null
        })}
      </tbody>
    </table>
  );
});

function abbreviateSurname(fullName) {
  var count = 1;

  var name = fullName.trim()
  
  while(name.length >= 30) {
    name = abbreviate(name, count)
    count++;
  }

  return name
}

function abbreviate(name, index) {
  var splitedName = name.split(" ")

  var surname = splitedName[index];

  return name.replace(surname, `${surname[0]}.`)
}

export default PrintComponent