import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";

import "./styles.css";

function Header({ children }) {
  const history = useHistory();

  //Nome da loja logada
  const [name, setName] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      api
        .get("scheduler", {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          setName(res.data.scheduler.name);
        });
    }
  }, []);

  function onLogout() {
    history.push("/login");
  }

  return (
    <header className="header-component">
      {children}
      <h2 className="title">Agenda Mercadótica</h2>
      <div className="button-logout">
        <strong>{name}</strong>
        <button onClick={() => onLogout()}>Sair</button>
      </div>
    </header>
  );
}

export default Header;
