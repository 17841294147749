import React from "react";
import Routes from "./routes";
import SwitchToggle from "./components/SwitchToggle";

import "./styles/global.css";
import "./styles/color.css";

function App() {
  return (
    <>
      <Routes />
      <SwitchToggle />
    </>
  );
}

export default App;
