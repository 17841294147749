function beforeToday(date, time) {
  const dateList = date.split("-");
  const day = dateList[2];
  const month = dateList[1];
  const year = dateList[0];

  const hours = time.split(":")[0]
  const minutes = time.split(":")[1]

  const dateSelected = new Date(year, month - 1, day, hours, minutes)
  const dateNow = new Date()

  if (dateNow.getTime() <= dateSelected.getTime()) {
    return false;
  }

  return true;
}

export default beforeToday;