import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../components/Header";
import api from "../../services/api";

import "./styles.css";

export default function AlterPassword() {
  const history = useHistory();

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  const [token, setToken] = useState("");

  //Recupera o token
  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token && token !== "") {
      api
        .get("scheduler", {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          setToken(token);

          res.data.name === "Administrador"
            ? history.push("/admin/alter")
            : history.push("");
        })
        .catch((err) => {
          history.push("/login");
        });
    } else {
      history.push("/login");
    }
  }, [history]);

  function alterPassword(event) {
    event.preventDefault();

    api
      .put(
        "scheduler",
        {
          name,
          password,
        },
        {
          headers: {
            authorization: token,
          },
        }
      )
      .then((res) => {
        alert("Senha alterada com sucesso!");
      })
      .catch((err) => {
        const error = err.response ? err.response.data.error : err.message;

        alert(error);
      });
  }

  return (
    <div className="alter-page">
      <Header />

      <form onSubmit={alterPassword}>
        <h2>Alterar senha</h2>

        <label htmlFor="name">Nome</label>
        <input
          name="name"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />

        <label htmlFor="password">Senha</label>
        <input
          name="password"
          type="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />

        <button className="submit" type="submit">
          Confirmar
        </button>
        <button className="cancel" onClick={() => history.push("/admin")}>
          Cancelar
        </button>
      </form>
    </div>
  );
}
