import api from "../services/api";

async function listTimes() {
  const token = localStorage.getItem("token");

  if (token === "") {
    return;
  }

  const interval = await api
    .get("time-interval", {
      headers: {
        authorization: token,
      },
    })
    .then((res) => {
      return res.data.timeInterval;
    })
    .catch((err) => {
      console.log({ err });
      return 10;
    });

  return buildTimes("08:00", "19:50", interval);
}

//initial: hora inicial (string) HH:MM
//final: hora final (string) HH:MM
//interval: tempo de intervalo entre os horarios em minutos
function buildTimes(initial, final, interval) {
  const initialTime = String(initial).split(":");
  var initialHour = parseInt(initialTime[0]);
  var initialMinutes = parseInt(initialTime[1]) + initialHour * 60;

  const finalTime = String(final).split(":");
  var finalHour = parseInt(finalTime[0]);
  var finalMinutes = parseInt(finalTime[1]) + finalHour * 60;

  var listTimes = [];

  for (let i = initialMinutes; i <= finalMinutes; i = i + interval) {
    var hour = parseInt(i / 60);
    var minutes = i % 60;

    if (hour < 10) {
      hour = "0" + hour;
    }

    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    listTimes.push(hour + ":" + minutes);
  }

  return listTimes;
}

export default listTimes;
