import compareDateTime from "./compareDateTime";
import listTimes from "./listTimes";

const { default: listDays } = require("./listDays");

async function buildScheduleMatriz(schedules, variant) {
  var matriz = [];
  var days = listDays(variant);
  var times = await listTimes();

  for (let i = 0; i < days.length; i++) {
    var list = [];
    for (let j = 0; j < times.length; j++) {
      var scheduleOfDay = null;
      // eslint-disable-next-line
      schedules.map((schedule) => {
        if (compareDateTime(schedule.date, days[i], times[j]) === 0)
          scheduleOfDay = schedule;

        return schedule;
      });
      list.push(scheduleOfDay);
    }
    matriz.push(list);
  }

  return matriz;
}

export default buildScheduleMatriz;
