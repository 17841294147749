import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../components/Header";
import Loading from "../../components/Loading";
import { FiChevronRight, FiChevronLeft, FiPlus } from "react-icons/fi";
import { BiCalendarEvent } from "react-icons/bi";
import {
  AiOutlineCalendar,
  AiOutlineMinus,
  AiOutlineSave,
  AiOutlineSetting,
} from "react-icons/ai";

import listTimes from "../../utils/listTimes";
import listDays from "../../utils/listDays";
import isToday from "../../utils/isToday";
import buildScheduleMatriz from "../../utils/buildScheduleMatriz";

import api from "../../services/api";

import "./styles.css";
import { MdClose } from "react-icons/md";

function HomeAdmin() {
  const history = useHistory();

  const [times, setTimes] = useState([]);
  const [weekShowing, setWeekShowing] = useState(0);
  const [daysWeekShowing, setDaysWeekShowing] = useState(listDays(weekShowing));

  const [newTimeInterval, setNewTimeInterval] = useState(10);

  //Tela que sera mostrada
  //0 - Agenda, 1 - Regras
  const [screenScheduling, setScreenScheduling] = useState(0);

  const [weekDateSelected, setWeekDateSelected] = useState(0);

  const [initialBlockDates, setInitialBlockDates] = useState([]);
  const [blockDates, setBlockDates] = useState([]);

  const [initialDateSelected, setInitialDateSelected] = useState("08:00");
  const [finalDateSelected, setFinalDateSelected] = useState("08:00");

  const [modalScheduling, setModalScheduling] = useState({
    id: -1,
    show: false,
  });

  const [matriz, setMatriz] = useState([]);

  const [doctors, setDoctors] = useState([]);

  const [loading, setLoading] = useState(false);

  const listDaysWeek = ["SEG", "TER", "QUA", "QUI", "SEX", "SÁB", "DOM"];

  //Recupera o token e a semana em destaque
  useEffect(() => {
    //pega o token
    const token = localStorage.getItem("token");

    if (token && token !== "") {
      api
        .get("scheduler", {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          res.data.scheduler.name === "Administrador"
            ? history.push("/admin")
            : res.data.scheduler.name.includes("admin")
            ? history.push("/home-admin")
            : history.push("/home");
        })
        .catch((err) => {
          history.push("/login");
        });
    } else {
      history.push("/login");
    }

    //pega a semana em destaque salva
    const variant = localStorage.getItem("weekShowing");
    variant ? setWeekShowing(parseInt(variant)) : setWeekShowing(0);
  }, [history]);

  useEffect(() => {
    listTimes().then((res) => setTimes(res));
  }, []);

  //Carrega os itens da semana em destaque
  useEffect(() => {
    //atualiza os dias da semana sendo mostrado
    setDaysWeekShowing(listDays(weekShowing));
    //grava no storage a semana que esta sendo mostrada
    localStorage.setItem("weekShowing", weekShowing);

    loadTimeInterval();
    loadSchedulings();
    loadDoctors();
    dateTimeRules();

    // eslint-disable-next-line
  }, [weekShowing]);

  async function loadTimeInterval() {
    const token = localStorage.getItem("token");

    if (token === "") {
      return;
    }

    await api
      .get("time-interval", {
        headers: {
          authorization: token,
        },
      })
      .then((res) => {
        setNewTimeInterval(res.data.timeInterval);
      })
      .catch((err) => console.log({ err }));
  }

  //Carrega os agendamentos da semana
  function loadSchedulings() {
    setLoading(true);

    const token = localStorage.getItem("token");

    if (token === "") {
      setLoading(false);
      history.push("/login");
      return;
    }

    //Pega a lista de agendamentos da semana
    api
      .get("scheduling", {
        headers: {
          authorization: token,
        },
        params: {
          initialDate: listDays(weekShowing)[0],
          finalDate: listDays(weekShowing)[6],
        },
      })
      .then((res) => {
        const schedules = res.data.map((item) => {
          return { ...item, date: new Date(item.date) };
        });

        buildScheduleMatriz(schedules, weekShowing)
          .then((res) => setMatriz(res))
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  //Carrega todos os médicos de cada dia da semana
  function loadDoctors() {
    const token = localStorage.getItem("token");

    if (token === "") {
      return;
    }

    setLoading(true);

    api
      .get("doctor", {
        headers: {
          authorization: token,
        },
        params: {
          date1: listDays(weekShowing)[0],
          date2: listDays(weekShowing)[1],
          date3: listDays(weekShowing)[2],
          date4: listDays(weekShowing)[3],
          date5: listDays(weekShowing)[4],
          date6: listDays(weekShowing)[5],
          date7: listDays(weekShowing)[6],
        },
      })
      .then((res) => {
        setDoctors(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  //Carrega as regras/bloqueio de horarios de agendamento
  function dateTimeRules() {
    const token = localStorage.getItem("token");

    if (!token) {
      history.push("/login");
      return;
    }

    api
      .get("rules", {
        headers: {
          authorization: token,
        },
      })
      .then((res) => {
        setBlockDates(res.data);
        setInitialBlockDates(res.data);
      })
      .catch((err) => {
        console.log({ err });
      });
  }

  //Remove um bloqueio de horario de agendamento
  function removePeriod(specificTime) {
    const listTimes = times;

    const initialIndex = listTimes.indexOf(specificTime || initialDateSelected);
    const finalIndex = listTimes.indexOf(specificTime || finalDateSelected) + 1;

    const listTimesSelected = listTimes.slice(initialIndex, finalIndex);

    var newBlockDates = blockDates.slice();

    listTimesSelected.map((item) => {
      const findItem = newBlockDates.find(
        (dates) => dates.time === item && dates.weekDate === weekDateSelected
      );

      if (!findItem) {
        newBlockDates.push({
          time: item,
          weekDate: weekDateSelected,
        });
      }

      return item;
    });

    setBlockDates(newBlockDates.filter((item) => item));
  }

  //Adiciona um bloqueio de horario de agendamento
  function addPeriod(specificTime) {
    const listTimes = times;

    const initialIndex = listTimes.indexOf(specificTime || initialDateSelected);
    const finalIndex = listTimes.indexOf(specificTime || finalDateSelected) + 1;

    const listTimesSelected = listTimes.slice(initialIndex, finalIndex);

    var newBlockDates = blockDates;

    listTimesSelected.map((item) => {
      newBlockDates = newBlockDates.map((date) => {
        if (date && date.time === item && date.weekDate === weekDateSelected) {
          return null;
        }
        return date;
      });
      return item;
    });

    setBlockDates(newBlockDates.filter((item) => item));
  }

  //Salva todas as alteracoes nos horarios de agendamento
  function saveServicesDates() {
    setInitialBlockDates(blockDates);

    const token = localStorage.getItem("token");

    api
      .post(
        "rules",
        {
          datesRule: blockDates,
        },
        {
          headers: {
            authorization: token,
          },
        }
      )
      .then((res) => {
        alert("Salvo!");
      })
      .catch((err) => {
        console.log({ err });
      });
  }

  //Compara a igualdade entre dois arrays
  function compareArrayServiceDay(array1, array2) {
    if (array1.length !== array2.length) return false;

    for (let i = 0; i < array1.length; i++) {
      if (
        array1[i].item !== array2[i].item ||
        array1[i].weekDate !== array2[i].weekDate
      )
        return false;
    }

    return true;
  }

  async function saveTimeInterval(e) {
    e.preventDefault();

    const token = localStorage.getItem("token");

    await api
      .post(
        "/time-interval",
        { timeInterval: Number(newTimeInterval) ?? 10 },
        {
          headers: {
            authorization: token,
          },
        }
      )
      .then(() => {
        listTimes().then((res) => setTimes(res));
        loadSchedulings();
      });
  }

  return (
    <div className="home-admin">
      <Loading show={loading} />

      {/* Modal para ver um agendamento */}
      <div
        className={
          modalScheduling.show ? "modal-scheduling" : "modal-scheduling hidden"
        }
      >
        <div className="modal-active">
          {matriz &&
            matriz.map((line) =>
              line.map((scheduling) => {
                if (scheduling && scheduling.id === modalScheduling.id) {
                  return (
                    <>
                      <h3 className="title">
                        Agendamento
                        <MdClose
                          className="icon-close"
                          onClick={() =>
                            setModalScheduling({ id: -1, show: false })
                          }
                        />
                      </h3>

                      <div className="grid-fields">
                        <div className="field">
                          <p>Status</p>
                          <p>
                            <div
                              className={`circle-status ${scheduling.status}`}
                            ></div>
                            {scheduling.status === "wait"
                              ? "Aguardando resposta"
                              : scheduling.status === "confirmed"
                              ? "Confirmado"
                              : "Não compareceu"}
                          </p>
                        </div>

                        <div className="field">
                          <p>Paciente</p>
                          <p>{`${scheduling.pacient} (Nº ${scheduling.numPacient})`}</p>
                        </div>

                        <div className="field">
                          <p>Data</p>
                          <p>
                            {Intl.DateTimeFormat("pt-br", {
                              day: "numeric",
                              month: "numeric",
                              year: "numeric",
                            }).format(new Date(scheduling.date))}
                          </p>
                        </div>

                        <div className="field">
                          <p>Hora</p>
                          <p>
                            {Intl.DateTimeFormat("pt-br", {
                              hour: "numeric",
                              minute: "numeric",
                            }).format(new Date(scheduling.date))}
                          </p>
                        </div>

                        <div className="field">
                          <p>Colaborador</p>
                          <p>{scheduling.collaborator}</p>
                        </div>

                        <div className="field">
                          <p>Valor</p>
                          <p>{scheduling.valuation}</p>
                        </div>

                        <div className="field">
                          <p>Convênio ou Taxa</p>
                          <p>{scheduling.tax}</p>
                        </div>

                        <div className="field">
                          <p>Telefone</p>
                          <p>{scheduling.phone}</p>
                        </div>

                        <div className="field">
                          <p>Endereço</p>
                          <p>{scheduling.address}</p>
                        </div>

                        <div className="field">
                          <p>Responsável</p>
                          <p>{`${
                            scheduling.responsable &&
                            scheduling.responsable`(Nº ${scheduling.numResponsable})`
                          }`}</p>
                        </div>

                        <div className="field">
                          <p>Observações</p>
                          <p>{scheduling.observations}</p>
                        </div>
                      </div>
                    </>
                  );
                }
                return null;
              })
            )}
        </div>
      </div>

      <Header />

      <main>
        {screenScheduling === 0 ? (
          <>
            <div className="header-calendar">
              <div className="item-actions">
                <button
                  onClick={() => setWeekShowing(weekShowing - 1)}
                  title="Ir para semana anterior"
                >
                  <FiChevronLeft />
                </button>
                <button
                  onClick={() => setWeekShowing(0)}
                  title="Ir para semana atual"
                >
                  <BiCalendarEvent />
                </button>
                <button
                  onClick={() => setWeekShowing(weekShowing + 1)}
                  title="Ir para próxima semana"
                >
                  <FiChevronRight />
                </button>
              </div>
              {matriz.map((days, index) => {
                return (
                  <div
                    key={daysWeekShowing[index]}
                    className={
                      isToday(daysWeekShowing[index])
                        ? "item-day today"
                        : "item-day"
                    }
                  >
                    <strong>
                      {daysWeekShowing[index].split("-")[2]}/
                      {daysWeekShowing[index].split("-")[1]}/
                      {daysWeekShowing[index].split("-")[0].slice(2, 4)}
                    </strong>
                    <strong>{listDaysWeek[index]}</strong>
                    <label>
                      {`( ${[0, ...days].reduce((result, scheduling) =>
                        scheduling ? result + 1 : result
                      )} )`}
                    </label>
                    <label
                      className={`${
                        doctors && doctors[index]
                          ? "name-doctor-day"
                          : "name-doctor-day no-doctor"
                      }`}
                    >
                      {doctors && doctors[index]
                        ? doctors[index].name
                        : "Sem médico"}
                    </label>
                  </div>
                );
              })}
              <div className="scroll-align" />
            </div>
            <div className="calendar">
              <div className="times-calendar">
                {times.map((item) => {
                  return <div key={item}>{item}</div>;
                })}
              </div>

              {matriz.map((days, index) => (
                <div className="days-calendar" key={index}>
                  {days.map((scheduling, indexScheduling) =>
                    scheduling ? (
                      <button
                        key={indexScheduling}
                        className={
                          isToday(daysWeekShowing[index])
                            ? `button-calendar today ${scheduling.status}`
                            : `button-calendar ${scheduling.status}`
                        }
                        onClick={() =>
                          setModalScheduling({ id: scheduling.id, show: true })
                        }
                      >
                        <input
                          className="pacient-name"
                          value={scheduling.pacient}
                          disabled
                        />
                      </button>
                    ) : (
                      <button
                        key={indexScheduling}
                        className={
                          initialBlockDates.find(
                            (item) =>
                              item.weekDate === index &&
                              item.time === times[indexScheduling]
                          )
                            ? "button-calendar block"
                            : "button-calendar"
                        }
                      ></button>
                    )
                  )}
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="header-calendar-rules">
              {matriz.map((days, index) => {
                return (
                  <div
                    key={daysWeekShowing[index]}
                    onClick={() => setWeekDateSelected(index)}
                    className={
                      weekDateSelected === index ? "item-day today" : "item-day"
                    }
                  >
                    <strong>{listDaysWeek[index]}</strong>
                  </div>
                );
              })}
            </div>

            <div className="switch-mode">
              <p className="subtitle">Períodos</p>

              <div className="input-time-custom">
                <div className="select-box">
                  <select
                    value={initialDateSelected}
                    onChange={(event) =>
                      setInitialDateSelected(event.target.value)
                    }
                  >
                    {times.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                  <p>até</p>
                  <select
                    value={finalDateSelected}
                    onChange={(event) =>
                      setFinalDateSelected(event.target.value)
                    }
                  >
                    {times.map((item) => {
                      if (item >= initialDateSelected) {
                        return (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        );
                      }
                      return null;
                    })}
                  </select>
                </div>
                <div className="button-box">
                  <button onClick={() => addPeriod()}>
                    <FiPlus className="icon" />
                    Adicionar horário
                  </button>
                  <button onClick={() => removePeriod()}>
                    <AiOutlineMinus className="icon" />
                    Remover horário
                  </button>
                </div>
              </div>

              <p className="subtitle">Intervalo de atendimento</p>

              <div className="box-time-interval">
                <input
                  className="input-time-interval"
                  value={newTimeInterval}
                  type="number"
                  onChange={(event) => setNewTimeInterval(event.target.value)}
                />

                <button onClick={saveTimeInterval}>Salvar</button>
              </div>

              <p className="subtitle">Personalizado</p>
            </div>

            <div className="calendar-rules">
              <div className="days-calendar">
                {matriz[0].map((scheduling, indexScheduling) =>
                  indexScheduling % 6 === 0 ? (
                    <div
                      className="group-button-calendar"
                      key={indexScheduling}
                    >
                      {[0, 1, 2, 3, 4, 5].map((index) => (
                        <button
                          key={indexScheduling + index}
                          className={
                            blockDates.find(
                              (item) =>
                                item.weekDate === weekDateSelected &&
                                item.time === times[indexScheduling + index]
                            )
                              ? "button-calendar"
                              : "button-calendar selected"
                          }
                          onClick={() =>
                            blockDates.find(
                              (item) =>
                                item.weekDate === weekDateSelected &&
                                item.time === times[indexScheduling + index]
                            )
                              ? addPeriod(times[indexScheduling + index])
                              : removePeriod(times[indexScheduling + index])
                          }
                        >
                          {times[indexScheduling + index]}
                        </button>
                      ))}
                    </div>
                  ) : null
                )}
              </div>
            </div>

            <div className="box-button">
              <div className="legend">
                <div className="legend-no-service-day"></div>
                Horário de agendamento bloqueado
                <div className="legend-service-day"></div>
                Horário de agendamento
              </div>

              {compareArrayServiceDay(blockDates, initialBlockDates) ? (
                <button className="block">
                  Salvar
                  <AiOutlineSave className="icon-save" />
                </button>
              ) : (
                <button onClick={() => saveServicesDates()}>
                  Salvar
                  <AiOutlineSave className="icon-save" />
                </button>
              )}
            </div>
          </>
        )}
      </main>

      <footer>
        <button
          className={`${screenScheduling === 0 && "highlight"}`}
          onClick={() => setScreenScheduling(0)}
        >
          <AiOutlineCalendar />
          <p>Agenda</p>
        </button>
        <button
          className={`${screenScheduling === 1 && "highlight"}`}
          onClick={() => setScreenScheduling(1)}
        >
          <AiOutlineSetting />
          <p>Regras</p>
        </button>
      </footer>
    </div>
  );
}

export default HomeAdmin;
