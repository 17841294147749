import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Loading from '../../components/Loading'

import api from "../../services/api";

import "./styles.css";

function Login() {
  const history = useHistory();

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    localStorage.setItem("token", null);
  }, []);

  function onLogin(event) {
    event.preventDefault();

    setLoading(true);

    if (name === "" || password === "") {
      setLoading(false);
      return;
    }

    api
      .post("login", { name, password })
      .then((res) => {
        if (res.data.admin) {
          history.push("admin");
        } else {
          if(res.data.shopAdmin) {
            history.push("home-admin");
          }else {
            history.push("home");
          }
        }
        localStorage.setItem("token", res.data.token);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        const error = err.response ? err.response.data.error : err.message;

        alert(error);
      });
  }

  return (
    <div className="login-page">
      <form onSubmit={onLogin}>
        <h2>Login</h2>
        <label htmlFor="name">Nome</label>
        <input
          className="input"
          name="name"
          type="name"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <label htmlFor="password">Senha</label>
        <input
          className="input"
          name="password"
          type="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
        <button
          type="submit"
          className={loading ? "button-submit loading" : "button-submit"}
          disabled={loading}
        >
          {loading ? (
            <Loading show={true} />
          ) : (
            "Entrar"
          )}
        </button>
      </form>
    </div>
  );
}

export default Login;
