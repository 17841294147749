import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import api from "../../services/api";
import { MdArrowBack } from "react-icons/md";
import { useHistory } from "react-router-dom";

import "./styles.css";

function ListSchedulers() {
  const history = useHistory();

  const [listSchedulers, setListSchedulers] = useState([]);

  const [token, setToken] = useState("");

  //Recupera o token
  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token && token !== "") {
      api
        .get("scheduler", {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          setToken(token);

          res.data.name === "Administrador"
            ? history.push("/admin/shops")
            : history.push("");
        })
        .catch((err) => {
          history.push("/login");
        });
    } else {
      history.push("/login");
    }
  }, [history]);

  useEffect(() => {
    const token = localStorage.getItem("token");

    api
      .get("scheduler/list", {
        headers: {
          authorization: token,
        },
      })
      .then((res) => {
        setListSchedulers(res.data);
      })
      .catch(err => {
        console.log({err})
      });
  }, [token]);

  return (
    <div className="list-schedulers-page">
      <Header />

      <main>
        <header>
          <MdArrowBack className="icon" size={25} onClick={() => history.push("/admin")} />
          <h2>Lista de lojas</h2>
        </header>

        <div className="list-schedulers">
          {listSchedulers.map((item) => {
            return (
              <div className="item-list">
                <label>{item.name}</label>
              </div>
            );
          })}
        </div>
      </main>
    </div>
  );
}

export default ListSchedulers;
