import React, { useState, useEffect } from "react";
import DateInput from "../DateInput";
import TimeInput from "../TimeInput";
import { FiX } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import InputMask from "react-input-mask";

import dateToString from "../../utils/dateToString";
import timeToString from "../../utils/timeToString";

import api from "../../services/api";

import "./styles.css";

function Modal({
  title,
  setVisiblility,
  initialTime,
  initialDate,
  scheduleId,
  setScheduleId,
}) {
  const history = useHistory();

  const [id, setId] = useState(0);
  const [date, setDate] = useState(dateToString(new Date()));
  const [time, setTime] = useState(initialTime);
  const [collaborator, setCollaborator] = useState("");
  const [pacient, setPacient] = useState("");
  const [numPacient, setNumPacient] = useState("");
  const [valuation, setValuation] = useState("");
  const [tax, setTax] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [responsable, setResponsable] = useState("");
  const [numResponsable, setNumResponsable] = useState("");
  const [observations, setObservations] = useState("");
  const [status, setStatus] = useState("");

  const [collaboratorError, setCollaboratorError] = useState(false);
  const [pacientError, setPacientError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [valuationError, setValuationError] = useState(false);

  const [token, setToken] = useState("");

  //Recupera o token
  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token && token !== "") {
      setToken(token);
    }
  }, []);

  useEffect(() => {
    setDate(initialDate);
    setTime(initialTime);

    if (scheduleId && scheduleId >= 0) {
      api
        .get(`scheduling/${scheduleId}`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          setId(parseInt(res.data.id));
          setDate(dateToString(new Date(res.data.date)));
          setTime(timeToString(res.data.date));
          setCollaborator(res.data.collaborator);
          setPacient(res.data.pacient);
          setNumPacient(res.data.numPacient);
          setValuation(getNumberOnValuation(res.data.valuation));
          setTax(res.data.tax);
          setPhone(res.data.phone);
          setAddress(res.data.address);
          setResponsable(res.data.responsable);
          setNumResponsable(res.data.numResponsable);
          setObservations(res.data.observations);
          setStatus(res.data.status);
        })
        .catch((err) => {});
    }
  }, [initialDate, initialTime, scheduleId, token]);

  function handleAdd(event) {
    event.preventDefault();

    if (!inputValidations()) {
      return;
    }

    if (!blockDatesValidations()) {
      alert("Só pode agendar em horários permitidos");
      return;
    }

    api
      .post(
        "scheduling",
        {
          pacient,
          numPacient: parseInt(numPacient) || 0,
          collaborator,
          valuation,
          tax,
          phone,
          address,
          responsable,
          numResponsable: parseInt(numResponsable) || 0,
          observations,
          date,
          time,
          timeZone: new Date().getTimezoneOffset(),
        },
        {
          headers: {
            authorization: token,
          },
        }
      )
      .then(() => {
        clearData();
      })
      .catch((err) => {
        console.log({ err });
        const error = err.response ? err.response.data.error : err.message;

        if (error === "Exists scheduling with this date-time") {
          alert(
            "Erro no agendamento, já existe agendamento nesse horário e nesse dia."
          );
        } else {
          alert("Erro no agendamento, revise os dados e tente novamente");
        }
      });
  }

  async function handleEdit(event) {
    event.preventDefault();

    if (status !== "cancel" && !inputValidations()) {
      return;
    }

    var valuationFormated = formatedValuation();

    if (await blockDatesValidations()) {
      alert("Só pode agendar em horários permitidos");
      return;
    }

    var newSchedule = {
      id,
      collaborator,
      pacient,
      numPacient: parseInt(numPacient) || 0,
      date,
      time,
      timeZone: new Date().getTimezoneOffset(),
      valuation: valuationFormated,
      tax,
      phone,
      address,
      responsable,
      numResponsable: parseInt(numResponsable) || 0,
      observations,
      status,
    };

    api
      .put("scheduling", newSchedule, {
        headers: {
          authorization: token,
        },
      })
      .then(() => {
        clearData();
      })
      .catch((err) => {
        const error = err.response ? err.response.error : err.message;

        if (error === "Exists scheduling with this date-time") {
          alert(
            "Erro no agendamento, já existe agendamento nesse horário e nesse dia."
          );
        } else {
          alert("Erro no agendamento, revise os dados e tente novamente");
        }
      });
  }

  function formatedValuation() {
    var res = "";

    try {
      res = `R$ ${Number(valuation).toFixed(2)}`;
    } catch (err) {
      res = "";
    }

    return res;
  }

  function getNumberOnValuation(valuation) {
    return valuation
      .replace(/\D/g, ".")
      .split(".")
      .filter((item) => item !== "")
      .join(".");
  }

  function inputValidations() {
    if (collaborator.trim() === "") {
      setCollaboratorError(true);
      setTimeout(() => setCollaboratorError(false), 3000);
      return false;
    }

    if (pacient.trim() === "") {
      setPacientError(true);
      setTimeout(() => setPacientError(false), 3000);
      return false;
    }

    if (formatedValuation().trim() === "") {
      setValuationError(true);
      setTimeout(() => setValuationError(false), 3000);
      return false;
    }

    if (phone.trim() === "") {
      setPhoneError(true);
      setTimeout(() => setPhoneError(false), 3000);
      return false;
    }

    return true;
  }

  async function blockDatesValidations() {
    const token = localStorage.getItem("token");

    if (!token) {
      history.push("/login");
      return;
    }

    const blockDates = await api
      .get("rules", {
        headers: {
          authorization: token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        return [];
      });

    const weekDate = new Date(date + " 00:00").getDay();

    var blockedItem = blockDates.find((item) => {
      if ((item.weekDate + 1) % 7 === weekDate && item.time === time) {
        return true;
      }
      return false;
    });

    if (blockedItem) {
      return true;
    }

    return false;
  }

  function clearData() {
    setScheduleId(-1);

    setCollaborator("");
    setPacient("");
    setNumPacient("");
    setValuation("");
    setAddress("");
    setTax("");
    setPhone("");
    setResponsable("");
    setNumResponsable("");
    setObservations("");

    setVisiblility(false);
  }

  return (
    <div className="modal">
      <div className="modal-visible">
        <header>
          <h2>{title}</h2>
          <button className="close-modal" onClick={clearData}>
            <FiX className="icon" />
          </button>
        </header>
        <form
          onSubmit={title === "Editar agendamento" ? handleEdit : handleAdd}
        >
          {title === "Editar agendamento" ? (
            <div className="status">
              <label className="label">Status</label>
              <select
                value={status}
                className="input"
                onChange={(event) => setStatus(event.target.value)}
              >
                <option value="wait">Aguardando resposta</option>
                <option value="cancel">Cancelado</option>
                <option value="confirmed">Confirmado</option>
                <option value="not-showed-up">Não compareceu</option>
              </select>
            </div>
          ) : null}

          <DateInput
            title="Data"
            value={date}
            onChange={(event) => setDate(event.target.value)}
          />

          <TimeInput
            title="Horário"
            value={time}
            onChange={(event) => setTime(event.target.value)}
          />

          <div
            className={
              collaboratorError
                ? "text-input colaborator error"
                : "text-input colaborator"
            }
          >
            <label className="label">Colaborador</label>
            <input
              className="input"
              type="text"
              value={collaborator}
              onChange={(event) => setCollaborator(event.target.value)}
            />
          </div>

          <div
            className={
              pacientError ? "text-input pacient error" : "text-input pacient"
            }
          >
            <label className="label">Paciente</label>
            <input
              className="input"
              type="text"
              value={pacient}
              onChange={(event) => setPacient(event.target.value)}
            />
          </div>

          <div className="text-input numPacient">
            <label className="label">CL Nº</label>
            <input
              className="input"
              type="number"
              value={numPacient}
              onChange={(event) => setNumPacient(event.target.value)}
            />
          </div>

          <div
            className={
              valuationError
                ? "text-input valuation error"
                : "text-input valuation"
            }
          >
            <label className="label">Valor</label>
            <input
              className="input"
              type="number"
              value={valuation}
              onChange={(event) => setValuation(event.target.value)}
            />
          </div>

          <div className="text-input tax">
            <label className="label">Convênio ou Taxa</label>
            <input
              className="input"
              value={tax}
              onChange={(event) => setTax(event.target.value)}
            />
          </div>

          <div
            className={
              phoneError ? "text-input phone error" : "text-input phone"
            }
          >
            <label className="label">Telefone</label>
            <InputMask
              className="input"
              mask="(99) 999999999"
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
            />
          </div>

          <div className="text-input address">
            <label className="label">Endereço</label>
            <input
              className="input"
              value={address}
              onChange={(event) => setAddress(event.target.value)}
            />
          </div>

          <div className="text-input responsable">
            <label className="label">Responsável</label>
            <input
              className="input"
              value={responsable}
              onChange={(event) => setResponsable(event.target.value)}
            />
          </div>

          <div className="text-input numResponsable">
            <label className="label">CL Nº</label>
            <input
              className="input"
              type="number"
              value={numResponsable}
              onChange={(event) => setNumResponsable(event.target.value)}
            />
          </div>

          <div className="text-input observations">
            <label className="label">Observações</label>
            <textarea
              className="input"
              value={observations}
              onChange={(event) => setObservations(event.target.value)}
            />
          </div>

          <div className="button-box">
            <button className="button" type="submit">
              Salvar
            </button>

            <button
              type="button"
              className="button secundary"
              onClick={clearData}
            >
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Modal;
