import React, { useEffect, useRef, useState } from "react";
import { MdClose } from "react-icons/md";
import ReactToPrint from "react-to-print";
import api from "../../services/api";
import PrintComponent from "../PrintComponent";
import Loading from "../Loading";

import "./styles.css";

export default function ModalReport({ setShow, onEditScheduling }) {
  const componentRef = useRef();

  const [schedulings, setSchedulings] = useState([]);
  const [schedulingsFiltered, setSchedulingsFiltered] = useState([]);

  const [periodSelectScheduling, setPeriodSelectScheduling] = useState({
    initial: "",
    final: "",
  });

  const [isFiltred, setIsFiltred] = useState(false);

  const [statusSelected, setStatusSelected] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [isBlockLoading, setIsBlockLoading] = useState(false);

  // eslint-disable-next-line
  useEffect(() => onSelectStatus(), [statusSelected]);

  useEffect(() => {
    setIsBlockLoading(false);
  }, [periodSelectScheduling]);

  function loadSchedulings() {
    if (isBlockLoading) {
      return;
    }

    const token = localStorage.getItem("token");

    setIsLoading(true);

    api
      .get("/scheduling", {
        headers: {
          authorization: token,
        },
        params: {
          initialDate: periodSelectScheduling.initial,
          finalDate: periodSelectScheduling.final,
        },
      })
      .then((res) => {
        setSchedulings(res.data);
        setSchedulingsFiltered(res.data);
        setStatusSelected(0);
        setIsFiltred(true);
        setIsLoading(false);
        setIsBlockLoading(true);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setIsBlockLoading(true);
      });
  }

  function goToScheduling(id) {
    onEditScheduling(id);
    setShow(false);
  }

  function onSelectPeriod(clean) {
    if (
      clean ||
      periodSelectScheduling.initial === "" ||
      periodSelectScheduling.final === ""
    ) {
      setSchedulings([]);
      setSchedulingsFiltered([]);
      setPeriodSelectScheduling({ initial: "", final: "" });
      setStatusSelected(0);
      setIsFiltred(false);
      return;
    }

    loadSchedulings();
  }

  function onSelectStatus() {
    const listStatus = ["all", "confirmed", "wait", "not-showed-up"];

    if (statusSelected === 0) {
      setSchedulingsFiltered(schedulings);
      return;
    }

    const filteredSchedulingNotShowedUp = schedulings.filter((scheduling) => {
      if (scheduling.status === listStatus[statusSelected]) {
        return true;
      }

      return false;
    });

    setSchedulingsFiltered(filteredSchedulingNotShowedUp);
  }

  return (
    <div className="modal-report">
      <div className="modal-active">
        <h3>Relatório de agendamentos</h3>
        <MdClose
          size={25}
          onClick={() => setShow(false)}
          className="icon-close"
        />

        <div className="select-period">
          <h4 className="select-title">Selecionar por período</h4>
          <div className="input-box">
            <input
              type="date"
              value={periodSelectScheduling.initial}
              onChange={(event) =>
                setPeriodSelectScheduling({
                  initial: event.target.value,
                  final:
                    event.target.value > periodSelectScheduling.final
                      ? event.target.value
                      : periodSelectScheduling.final,
                })
              }
            />
            <input
              type="date"
              value={periodSelectScheduling.final}
              onChange={(event) =>
                setPeriodSelectScheduling({
                  final: event.target.value,
                  initial:
                    event.target.value < periodSelectScheduling.initial
                      ? event.target.value
                      : periodSelectScheduling.initial,
                })
              }
            />
          </div>

          <div className="button-box">
            <button className="button-select" onClick={() => onSelectPeriod()}>
              Gerar relatório
            </button>
            <button
              className="clear-select"
              onClick={() => onSelectPeriod(true)}
            >
              Limpar
            </button>
          </div>
        </div>

        <div className="select-status">
          <button
            className={`option${statusSelected === 0 ? " selected" : ""}`}
            onClick={() => setStatusSelected(0)}
          >
            <p>Todos</p>
          </button>
          <button
            className={`option${statusSelected === 1 ? " selected" : ""}`}
            onClick={() => setStatusSelected(1)}
          >
            <p>Confirmados</p>
          </button>
          <button
            className={`option${statusSelected === 2 ? " selected" : ""}`}
            onClick={() => setStatusSelected(2)}
          >
            <p>Aguardando resposta</p>
          </button>
          <button
            className={`option${statusSelected === 3 ? " selected" : ""}`}
            onClick={() => setStatusSelected(3)}
          >
            <p>Não comparecidos</p>
          </button>
        </div>

        <div className="schedulings-not-showed-up">
          {isLoading ? (
            <Loading show={isLoading} />
          ) : (
            <>
              {isFiltred && (
                <h3 className="numberOfSchedulings">
                  {`${schedulingsFiltered.length} agendamento${
                    schedulingsFiltered.length === 1 ? "" : "s"
                  }`}
                </h3>
              )}

              {schedulingsFiltered.map((scheduling) => (
                <div className="item-scheduling" key={scheduling.id}>
                  <div className="box-text">
                    <strong>Paciente</strong>
                    <p>{scheduling.pacient}</p>
                  </div>
                  <div className="box-text">
                    <strong>Colaborador</strong>
                    <p>{scheduling.collaborator}</p>
                  </div>
                  <div className="box-text">
                    <strong>Horário</strong>
                    <p>
                      {Intl.DateTimeFormat("pt-br", {
                        hour: "numeric",
                        minute: "numeric",
                        day: "numeric",
                        month: "numeric",
                        year: "numeric",
                      }).format(new Date(scheduling.date))}
                    </p>
                  </div>
                  <div className="box-text">
                    <strong>Telefone</strong>
                    <p>{scheduling.phone}</p>
                  </div>
                  <button onClick={() => goToScheduling(scheduling.id)}>
                    Ir para o agendamento
                  </button>
                </div>
              ))}
            </>
          )}
        </div>

        <ReactToPrint
          trigger={() => (
            <button
              disabled={schedulingsFiltered.length === 0}
              className="print-button"
            >
              Imprimir
            </button>
          )}
          content={() => componentRef.current}
        />

        {/* Div invisivel para chamar o componente mas nao mostra-lo */}
        <div style={{ display: "none" }}>
          <PrintComponent
            schedulings={schedulingsFiltered}
            goToScheduling={goToScheduling}
            ref={componentRef}
            title="Relatório de agendamentos"
            period={periodSelectScheduling}
          />
        </div>
      </div>
    </div>
  );
}
