function isToday(date) {
  const dateList = date.split("-");
  const day = dateList[2];
  const month = dateList[1];
  const year = dateList[0];

  const nowDateList = new Date()
    .toLocaleDateString()
    .split("/")
    .reverse()
    .join("-")
    .split("-");
  const nowDay = nowDateList[2];
  const nowMonth = nowDateList[1];
  const nowYear = nowDateList[0];

  if (nowDay !== day || nowYear !== year || nowMonth !== month) {
    return false;
  }

  return true;
}

export default isToday;
