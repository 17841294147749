import React from "react";
import Lottie from "react-lottie";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useHistory } from "react-router-dom";

import animationData from "../../assets/animation-schedule.json";
import logoMercadotica from '../../assets/mercadotica.png';

import "./styles.css";

function Presentation() {
  const history = useHistory();

  return (
    <div className="presentation-page">
      <main>
        <Lottie
          autoplay
          height={250}
          width={250}
          loop
          options={{
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
        />
        <div className="actions">
          <h2>
            Agenda
            <br/>
            <img src={logoMercadotica} alt="Mercadótica" className="title-logo" />
          </h2>
          <button onClick={() => history.push("login")}>
            Login
            <AiOutlineArrowRight className="icon" />
          </button>
        </div>
      </main>
    </div>
  );
}

export default Presentation;
