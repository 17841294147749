function listDays(variant) {
  var listDays = [];

  //Pega a data atual
  const now = new Date();
  //Pega o dia da semana atual e subtrai por 1 para comecar na segunda
  var dayOfWeek = now.getDay() - 1;

  //Calcula o primeiro dia da semana, baseado no dia da semana e na variante da semana
  now.setTime(now.getTime() - 86400000 * dayOfWeek + variant * 86400000 * 7);

  //Intera 7 vezes para cada dia da semana
  for (let i = 0; i < 7; i++) {
    //Armazena na lista o dia ja no formato certo
    listDays.push(now.toLocaleDateString().split("/").reverse().join("-"));
    //Adianta mais um dia para ir a proxima interacao
    now.setTime(now.getTime() + 86400000);
  }

  return listDays;
}

export default listDays;
