import React, { useEffect, useRef, useState } from "react";
import { MdClose } from "react-icons/md";
import ReactToPrint from "react-to-print";
import api from "../../services/api";
import compareDateTime from "../../utils/compareDateTime";
import PrintComponent from "../PrintComponent";

import "./styles.css";

export default function ModalCanceled({ onEditScheduling, setShow }) {
  const componentRef = useRef();

  const [schedulings, setScheduling] = useState([]);
  const [schedulingsFiltered, setSchedulingFiltered] = useState([]);

  const [periodSelectScheduling, setPeriodSelectScheduling] = useState({
    initial: "",
    final: "",
  });

  useEffect(() => loadSchedulings(), []);

  function loadSchedulings() {
    const token = localStorage.getItem("token");

    api
      .get("/scheduling/canceled", {
        headers: {
          authorization: token,
        },
      })
      .then((res) => {
        setScheduling(res.data);
        setSchedulingFiltered(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function goToScheduling(id) {
    onEditScheduling(id);
    setShow(false);
  }

  function onSelectPeriod(clean) {
    if (
      clean ||
      periodSelectScheduling.initial === "" ||
      periodSelectScheduling.final === ""
    ) {
      setSchedulingFiltered(schedulings);
      setPeriodSelectScheduling({ initial: "", final: "" });
      return;
    }

    const filteredSchedulingNotShowedUp = schedulings.filter((scheduling) => {
      const compareInitial = compareDateTime(
        scheduling.date,
        periodSelectScheduling.initial,
        "00:00"
      );
      const compareFinal = compareDateTime(
        scheduling.date,
        periodSelectScheduling.final,
        "23:59"
      );

      if (compareInitial >= 0 && compareFinal <= 0) {
        return true;
      }

      return false;
    });

    setSchedulingFiltered(filteredSchedulingNotShowedUp);
  }

  return (
    <div className="modal-canceled">
      <div className="modal-active">
        <h3>Agendamentos cancelados</h3>
        <MdClose
          size={25}
          onClick={() => setShow(false)}
          className="icon-close"
        />

        <div className="select-period">
          <h4 className="select-title">Selecionar por período</h4>
          <div className="input-box">
            <input
              type="date"
              value={periodSelectScheduling.initial}
              onChange={(event) =>
                setPeriodSelectScheduling({
                  initial: event.target.value,
                  final:
                    event.target.value > periodSelectScheduling.final
                      ? event.target.value
                      : periodSelectScheduling.final,
                })
              }
            />
            <input
              type="date"
              value={periodSelectScheduling.final}
              onChange={(event) =>
                setPeriodSelectScheduling({
                  final: event.target.value,
                  initial:
                    event.target.value < periodSelectScheduling.initial
                      ? event.target.value
                      : periodSelectScheduling.initial,
                })
              }
            />
          </div>

          <div className="button-box">
            <button className="button-select" onClick={() => onSelectPeriod()}>
              Gerar relatório
            </button>
            <button
              className="clear-select"
              onClick={() => onSelectPeriod(true)}
            >
              Limpar
            </button>
          </div>
        </div>

        <div className="schedulings-not-showed-up">
          <h3 className="numberOfSchedulings">
            {`${schedulingsFiltered.length} agendamento${
              schedulingsFiltered.length === 1 ? "" : "s"
            }`}
          </h3>

          {schedulingsFiltered.map((scheduling) => (
            <div className="item-scheduling" key={scheduling.id}>
              <div className="box-text">
                <strong>Paciente</strong>
                <p>{scheduling.pacient}</p>
              </div>
              <div className="box-text">
                <strong>Colaborador</strong>
                <p>{scheduling.collaborator}</p>
              </div>
              <div className="box-text">
                <strong>Horário</strong>
                <p>
                  {Intl.DateTimeFormat("pt-br", {
                    hour: "numeric",
                    minute: "numeric",
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                  }).format(new Date(scheduling.date))}
                </p>
              </div>
              <div className="box-text">
                <strong>Telefone</strong>
                <p>{scheduling.phone}</p>
              </div>
              <button onClick={() => goToScheduling(scheduling.id)}>
                Ir para o agendamento
              </button>
            </div>
          ))}
        </div>

        <ReactToPrint
          trigger={() => (
            <button
              disabled={schedulingsFiltered.length === 0}
              className="print-button"
            >
              Imprimir
            </button>
          )}
          content={() => componentRef.current}
        />

        {/* Div invisivel para chamar o componente mas nao mostra-lo */}
        <div style={{ display: "none" }}>
          <PrintComponent
            schedulings={schedulingsFiltered}
            goToScheduling={goToScheduling}
            ref={componentRef}
            title="Relatório de agendamentos (não comparecidos)"
            period={periodSelectScheduling}
          />
        </div>
      </div>
    </div>
  );
}
